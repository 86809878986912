import React from 'react';
import SignIn from "./components/Auth.js"
import axios from "axios";
import EventsTable from "./components/EventList.js"
import LSPEventPageDemo from "./components/SportsbookEventPageDemo.js"
import {testFeed} from './utils.js'
 

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      auth: null,
      activeEvent: null
    };
  }

  componentDidMount(){
    let item = localStorage.getItem("auth")
    try{
      this.setState({auth: JSON.parse(item)})
    }catch{

    }
  }

  setActiveEvent(eventId){
    this.setState({activeEvent: eventId})
  }

  async doLogin(login, password) {
    let res;
    if (testFeed) {
      res = await axios.get(`${testFeed}kambi-rest-api/gameLauncher2.json`)
    } else {
      try {
        res = await axios.post("/auth", {user: login, password: password});
      } catch (e){
        alert(e.response.data)
      }
    }

    let data = {
      user: login,
      offering: res.data.offering,
      lang: res.data.lang,
      market: res.data.market,
      currency: res.data.currency,
      jurisdiction: res.data.jurisdiction,
      kambiOfferingApiBaseUrl: res.data.kambiOfferingApiBaseUrl,
      kambiPlayerApiBaseURL: res.data.kambiPlayerApiBaseURL,
      kambiPushWSUrl: res.data.kambiPushWSUrl,
      oddsFormat: "decimal",
      playerId: res.data.playerId,
      valueStab: res.data.valueStab,
    }
    this.setState({auth: data})
    localStorage.setItem("auth", JSON.stringify(data))
  }

  logout(){
    localStorage.removeItem("auth")
    this.setState({auth: null})
  }

  render(){
    return (
      <div>
        {!this.state.auth && <SignIn doLogin={this.doLogin.bind(this)} />}
        {(this.state.auth && !this.state.activeEvent) && <EventsTable 
        logout={this.logout.bind(this)} 
        auth={this.state.auth} 
        setActiveEvent={this.setActiveEvent.bind(this)}
        />}
        {this.state.activeEvent && <LSPEventPageDemo
        activeEvent={this.state.activeEvent} 
        auth={this.state.auth} 
        setActiveEvent={this.setActiveEvent.bind(this)}
        />}
      </div>
    )
  }
  
}

export default App;
