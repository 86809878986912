import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import axios from "axios";
import moment from "moment";
import {testFeed, allLive, urlParams, isLobby} from "../utils.js"
import Lobby from './LobbyView.js';

export default class EventsTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            events: []
        }
        this.interval = null;
    }


    componentDidMount() {
        if (this.props.auth && urlParams.get('eventId')) {
            this.props.setActiveEvent(urlParams.get('eventId'))
            return
        }
        this.refresh()
        this.interval = setInterval(this.refresh.bind(this), 7000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    async refresh() {
        let newEvents = [];
        let url = `${testFeed || 'https://eu-offering.kambicdn.org/'}offering/v2018/${testFeed?'ub':this.props.auth.offering}/listView/all/all/all/all/streaming.json?lang=${this.props.auth.lang}&market=${this.props.auth.market}&client_id=2&channel_id=1&ncid=${new Date().getTime()}&useCombined=true`
        if (allLive){
            url = `https://eu-offering.kambicdn.org/offering/v2018/${this.props.auth.offering}/event/live/open.json?lang=${this.props.auth.lang}&market=${this.props.auth.market}&client_id=2&ncid=${new Date().getTime()}`
        }
        let res = await axios.get(url);
        res.data[allLive?"liveEvents":"events"]
            .forEach((element) => {
                let event = element["event"];
                newEvents.push({
                    name: event["name"],
                    sport: event["path"][0]["name"],
                    id: event["id"],
                    start: moment(event["start"]).fromNow(),
                    started: event["state"] === "STARTED",
                });
            });
        this.setState({ events: newEvents.slice(0, 100) })

    }

    render() {
        if (isLobby) return <Lobby 
            logout={this.props.logout}
            events={this.state.events}
            setActiveEvent={this.props.setActiveEvent}
        />
        return (
            <Container>
                <Grid container justify="center">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => { this.props.logout() }}
                    >
                        Log out
                    </Button>
                </Grid>

                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell align="right">Start</TableCell>
                                <TableCell align="right">Sport</TableCell>
                                <TableCell align="right">Play</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.events.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.start}</TableCell>
                                    <TableCell align="right">{row.sport}</TableCell>
                                    <TableCell align="right">
                                        {row.started && <svg
                                            width="2em"
                                            height="2em"
                                            viewBox="0 0 16 16"
                                            className="bi bi-play-fill"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ color: "green" }}
                                            onClick={()=>{this.props.setActiveEvent(row.id)}}
                                        >
                                            <path
                                                d="M11.596 8.697l-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"
                                            />
                                        </svg>}
                                        {!row.started && <svg
                                            width="2em"
                                            height="2em"
                                            viewBox="0 0 16 16"
                                            className="bi bi-play-fill"
                                            fill="currentColor"
                                            xmlns="http://www.w3.org/2000/svg"
                                            style={{ color: "#94943c" }}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 15A7 7 0 1 0 8 1a7 7 0 0 0 0 14zm8-7A8 8 0 1 1 0 8a8 8 0 0 1 16 0z"
                                            />
                                            <path
                                                fillRule="evenodd"
                                                d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                                            />
                                        </svg>}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        );
    }
}