import OnlyVideo from './OnlyVideo'
import {getStream} from '../kambiClient'
import '../style/lobby.css'
import waiting from '../style/time-left.png'


export default function Lobby(props){
    
    const sports = ()=>{
        let sportsList = new Set()
        props.events.forEach(event => {sportsList.add(event.sport)});
        return [...sportsList].sort();
    }

    const sportRelatedEvents = (sport)=>{
        return props.events.filter(ev=>ev.sport===sport)
    }

    return <div>
        <div className='header'>
            <button className='logout' onClick={() => { props.logout() }}>Logout</button>
        </div>
        <div className='content'>
             {sports().map((sport)=>(
                 <div key={sport}>
                    <h2 className='sportHeader'>{sport}</h2>
                    <div className='sportVideos'>
                        {sportRelatedEvents(sport).map(ev=>(
                            <div key={ev.id}>
                                <div className='eventContainer' onClick={()=>{ev.started && props.setActiveEvent(ev.id)}}>
                                    {ev.started && 
                                    <OnlyVideo getUrlOfVideoStream={async ()=>{return await getStream(ev.id)}}/>
                                    }
                                    {!ev.started && 
                                        <div className='imagewrap'><img src={waiting} className='waiting' alt="Not started"/></div>
                                    }                                  
                                </div>
                                <div className='eventInfo'>
                                    <div>{ev.name}</div>
                                    <div>{ev.start}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
             ))}
        </div>
    </div>
}