import React from "react"
import Hls from 'hls.js';
import {isLobby} from "../utils"

export default class OnlyVideo extends React.Component {
    constructor(props) {
        super(props)
        this.video = React.createRef()
    }

    componentDidMount() {
        this.props.getUrlOfVideoStream().then((url) => {
            let video = this.video.current;
            if (Hls.isSupported()) {
                let hls = new Hls();
                hls.loadSource(url);
                hls.attachMedia(video);
                hls.on(Hls.Events.MANIFEST_PARSED, function () {
                    video.play()
                });
            } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = url;
                video.addEventListener('loadedmetadata', function () {
                    video.play()
                });
            }
        })
        if (!isLobby) document.documentElement.style.background = 'black'
    }

    componentWillUnmount() {
        if (!isLobby) document.documentElement.style.background = 'white'
    }

    style(){
        if (isLobby) return {'height': '100%','width': '100%'}
        return {
            'height': '100%',
            'object-fit': 'cover',
            'position': 'fixed',
            'top': '0',
            'left': '50%',
            'transform': 'translate(-50%, 0)',
        }
    }

    render() {
        return (
            <div>
                <video ref={this.video} muted={true}
                       style={this.style()}/>
            </div>
        )
    }
}